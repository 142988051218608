<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="outer">
          <div class="shopTopSearch">
            <div class="topSearchList">
              <span>是否自动发货：</span>
              <el-select v-model="send_switch" size="small" clearable placeholder="请选择">
                <el-option key="0" label="手动" value="0"></el-option>
                <el-option key="1" label="自动" value="1"></el-option>
              </el-select>
            </div>
            <div class="topSearchList">
              <span>是否过期：</span>
              <el-select v-model="isExpireTime" size="small" clearable placeholder="请选择">
                <el-option key="0" label="已过期" value="0"></el-option>
                <el-option key="1" label="未过期" value="1"></el-option>
              </el-select>
            </div>
            <div class="topSearchList">
              <span>店铺名称：</span>
              <el-input v-model="shopName" style="width: 200px" clearable size="small" placeholder="店铺名称"></el-input>
            </div>
            <div class="topSearchList">
              <span>账号名称：</span>
              <el-input v-model="ownerName" style="width: 200px" clearable size="small" placeholder="账号名称"></el-input>
            </div>
          </div>
          <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleMenuOpen">
              <el-menu-item index="">全部</el-menu-item>
              <el-menu-item index="0">淘宝</el-menu-item>
              <el-menu-item index="1">阿里巴巴</el-menu-item>
              <el-menu-item index="2">京东</el-menu-item>
              <el-menu-item index="3">拼多多</el-menu-item>
              <el-menu-item index="4">抖音</el-menu-item>
              <el-menu-item index="6">快手</el-menu-item>
              <el-menu-item index="7">淘工厂</el-menu-item>
            </el-menu>
            <div class="menu-right">
              <el-button size="small" @click="refreshStoreList">搜索</el-button>
              <el-button @click="addStore" size="small" type="primary">添加店铺</el-button>
              <el-button size="small" type="primary"><a href="https://v.qq.com/x/page/l3328bs85i7.html" target="_blank" style="color:#fff">添加店铺视频教程</a></el-button>
            </div>
          </div>
          <div class="table-outer">
            <el-table :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}" :data="tableData">
              <el-table-column label="序号">
                <template slot-scope="scope"><span>{{scope.$index+(currentPage - 1) * pageSize + 1}}</span></template>
              </el-table-column>
              <el-table-column label="平台">
                <template slot-scope="scope">{{ getShopName(scope.row.band_type) }}</template>
              </el-table-column>
              <el-table-column label="店铺名称" prop="shopName"></el-table-column>
              <el-table-column label="账号名" prop="ownerName"></el-table-column>
              <el-table-column label="到期截止时间" >
                <template slot-scope="scope"><span>{{timeConversion(scope.row.expireTime)}}</span></template>
              </el-table-column>
              <el-table-column label="自动发货">
              <template slot-scope="scope"><el-switch v-model="scope.row.send_switch" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="handleSwitchChangeDebounce(scope.row)"></el-switch></template>
              </el-table-column>
              <el-table-column label="授权状态">
              <template slot-scope="scope">{{ scope.row.isExpireTime == 0 ? '已过期' : '未过期' }}</template>
              </el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="Orderran(scope.row)" v-if="datetime>(scope.row.expireTime)">重新绑定</el-button>
                  <el-button size="mini"
                    @click="OrderDelete(scope.row)">删除绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pagination">
              <el-pagination
                background
                @current-change="currentChange"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :current-page="currentPage"
                :total="total">
              </el-pagination>
            </div>
          </div>
          <el-dialog
            title="添加店铺"
            :visible.sync="dialogVisible"
            width="70%"
            >
            <div class="content">
              <span class="storestt">第一步：</span>
              <div class="stores">
                <div @click="storesItemClick(item.value)" class="stores-item" :class="{'active': storesIndex === item.value}" v-for="(item) in stores" :key="item.value">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
            <p style="text-indent: 100px;margin: 20px 0;font-size: 20px;">选择打单服务：</p>
            <div class="content">
              <span class="storestt">第二步：</span>
              <div class="stores">
                <div class="stores-item" @click="serveIndex = 4, getding()">
                  <span>点击订购服务</span>
                </div>
              </div>
            </div>
            <p style="margin:20px 0 20px 100px;font-size: 14px;color: #06c;">备注：<br />① 点击订购服务-在新打开的窗口页面(购买服务),按自己须求选择购买时间<br/> ②店铺授权绑定! <br/>③完成操作后按F5刷新，查看店铺列表是否成功绑定。</p>
            <div class="content">
              <span class="storestt">第三步：</span>
              <div class="stores">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleBindConfirm"> 确定授权</el-button>
              </div>
            </div>
          </el-dialog>
          <el-dialog
            :visible.sync="successDialogVisible"
            width="20%"
            center
            >
            <span class="content">
              是否授权成功？
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="successDialogVisible = false">授权失败</el-button>
              <el-button type="primary" @click="handleSuccessBind">我已成功授权</el-button>
            </span>
          </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisibleInfo"
            width="30%">
            <span>请先订购服务<span style="color: red"></span>在进行授权绑定!</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="getding">去订购服务</el-button>
              <el-button type="primary" @click="handleBindConfirm">重新绑定</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { getBandStore, autoChange, getBandDelete, getYcAuthApi, bindYcShopApi, getYcAuthServiceUrl } from '../../../network/api'
import { timeConversion } from 'utils/utils'
// import { shouquan } from 'network/api'

export default {
  components: {
    AppMain
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  data () {
    return {
      activeIndex: '',
      dialogVisible: false,
      successDialogVisible: false,
      currentPage: 1, // 当前页
      pageSize: 10,
      // stores: ['淘宝/淘特/天猫', '拼多多', '抖音', '京东', '快手', '阿里巴巴', '淘工厂'], //, '抖音'
      stores: [
        {
          name: '淘宝/淘特/天猫',
          value: '0'
        },
        {
          name: '拼多多',
          value: '3'
        },
        {
          name: '抖音',
          value: '4'
        },
        {
          name: '京东',
          value: '2'
        },
        {
          name: '快手',
          value: '6'
        },
        {
          name: '阿里巴巴',
          value: '1'
        },
        {
          name: '淘工厂',
          value: '7'
        }
      ],
      storesIndex: '0',
      // 0 淘宝 1 拼多多 2 抖音
      storeBindLink: [],
      tableData: [],
      allStoreList: [],
      pinStoreList: [],
      taoStoreList: [],
      douYinStoreList: [],
      timer: null, // vue中防抖函数 坑！
      firstEntery: true, // 标记是否是第一次进入页面
      datetime: '',
      dingurl: [],
      ding: 'https://fuwu.taobao.com/ser/detail.htm?service_code=FW_GOODS-1000360715',
      ownerName: '',
      shopName: '',
      send_switch: '',
      total: 0,
      isExpireTime: '',
      dialogVisibleInfo: false,
      infoRow: {},
      // 订购服务index
      serveIndex: 8
    }
  },
  created () {
    this.datetime = Date.parse(new Date())
    this.refreshStoreList()
  },
  methods: {
    addStore () {
      this.storesIndex = 0
      this.infoRow = {}
      // this.dialogVisible = true
      this.dialogVisible = true
    },
    storesItemClick (index) {
      this.storesIndex = index
    },
    getShopName (type) {
      let name = ''
      this.stores.map(item => {
        if (item.value === type) {
          name = item.name
        }
      })
      return name
    },
    getding () {
      getYcAuthServiceUrl({ band_type: this.storesIndex }).then(res => {
        if (!res.data.service_url || res.data.service_url === '') {
          this.$message({
            message: '该平台无需订购服务即可授权',
            type: 'info'
          })
        } else {
          window.open(res.data.service_url, '_blank')
        }
      })
    },
    currentChange (val) {
      this.currentPage = val
      this.refreshStoreList()
    },
    Orderran (row) {
      this.storesIndex = row.band_type
      this.dialogVisibleInfo = true
    },
    OrderDelete (row) {
      getBandDelete({ storeId: row.id }).then(res => {
        this.refreshStoreList()
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    },
    handleBindConfirm () {
      this.dialogVisible = false
      this.dialogVisibleInfo = false
      this.successDialogVisible = true
      getYcAuthApi({ band_type: this.storesIndex }).then(res => {
        this.bindKey = res.data.state
        window.open(res.data.url, '_blank')
      })
    },
    handleSuccessBind () {
      // close the dialog
      this.successDialogVisible = false
      if (this.bindKey) {
        bindYcShopApi({ band_type: this.storesIndex, shop_name: this.bindKey }).then(res => {
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'info'
            })
          }
          this.refreshStoreList()
        })
      } else {
        this.refreshStoreList()
      }
    },
    handleSwitchChangeDebounce (row) {
      this.debounce(this.handleSwitchChange, 1000)(row)
    },
    handleSwitchChange (row) {
      // console.log(row)
      autoChange({
        id: row.id,
        status: row.send_switch
      }).then(res => {
        let type
        res.code === 1 ? type = 'success' : type = 'error'
        this.$message({
          message: res.msg,
          type
        })
      })
    },
    // 防抖函数
    debounce (fn, delay) {
      const that = this
      return function () {
        const args = arguments
        if (that.timer) clearTimeout(that.timer)
        that.timer = setTimeout(() => { fn.apply(that, args) }, delay)
      }
    },
    refreshStoreList () {
      // this.allStoreList = []
      // this.taoStoreList = []
      // this.pinStoreList = []
      // this.douYinStoreList = []
      getBandStore({
        page: this.currentPage,
        band_type: this.activeIndex,
        ownerName: this.ownerName,
        send_switch: this.send_switch,
        isExpireTime: this.isExpireTime,
        shopName: this.shopName
      }).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        // res.data got the store information
        // for (let i = 0; i < res.data.length; i++) {
        //   // eslint-disable-next-line eqeqeq
        //   if (Number(res.data[i].band_type) === 0) {
        //     this.taoStoreList.push(res.data[i])
        //   } else if (Number(res.data[i].band_type) === 3) {
        //     this.pinStoreList.push(res.data[i])
        //   }
        // }
        // this.allStoreList = res.data
        // if (!this.firstEntery) {
        //   this.$message({
        //     message: '刷新成功',
        //     type: 'success'
        //   })
        // }
        // first entery the page,do not show the refresh success message.
        // this.firstEntery = false
      })
    },
    refresh () {
      this.debounce(this.refreshStoreList, 1000)()
    },
    handleMenuOpen (index) {
      this.activeIndex = index
      this.refreshStoreList()
      // index is string type
      // switch (+index) {
      //   case 0:
      //     this.tableData = this.allStoreList
      //     break
      //   case 1:
      //     this.tableData = this.taoStoreList
      //     break
      //   case 2:
      //     // alibaba data
      //     this.tableData = []
      //     break
      //   case 3:
      //     this.tableData = this.pinStoreList
      //     break
      //   case 4:
      //     this.tableData = []
      //     break
      // }
    },
    // 时间戳转化
    timestampToTime (timestamp) {
      var newtime = timestamp.slice(0, 10)
      return Date.parse(newtime)
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/storeBind.css">
</style>

<style>
.outer .el-dialog__header {
  border-bottom: solid 1px #e8e8e8;
}

.outer .el-dialog__footer {
  border-top: solid 1px #e8e8e8;
}
.shopTopSearch{
  padding: 15px;
  display: flex;
  align-items: center;
}
.topSearchList{
  display: flex;
  align-items: center;
  width: 400px;
}
</style>
